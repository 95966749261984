import { arrow } from "@tightrope/newtab";

const Util = require("./util.js");

const { TOP_DOMAIN } = Util;

$(document).ready(async function() {
    const searchElement = "search-text";
    if (searchElement && searchElement !== "") window._tr_ac_se = searchElement;
    const userGuid = await Util.getGuid();
    arrow("newtab");
    if (new URL(window.location.href).searchParams.get("tutorial") === "true") {
        window.scrollTo(0, 450);
        $(".firstrun").css("display", "block");
        $("body").css({ overflow: "hidden" });
        $(".step-a").click(function() {
            $(".step-a").fadeOut();
            $(".step-b").show();
            $(".b-card").slideDown();
            $("body").css({ overflow: "scroll" });
        });

        $(".step-b").click(function() {
            $(".b-card").slideUp();
            $(".firstrun").hide();
        });
    }

    $("#search-button").click(function() {
        $("#search-form").submit();
    });

    $("#search-form").submit(e => {
        e.preventDefault();
        window.location = `https://services.${TOP_DOMAIN}/crx/search.php?action=nt&guid=${userGuid}&k=${$(
            "#search-text"
        ).val()}`;
    });

    $("a.pdfLink, a.homepageLinks").click(e => {
        if (
            $(e.currentTarget)
                .attr("href")
                .includes("pdf-services")
        )
            return;
        const qp = ["guid", "extId"]
            .map(p =>
                Util.getSetting(p)
                    ? `${p.toLowerCase()}=${Util.getSetting(p)}`
                    : undefined
            )
            .filter(x => x)
            .join("&");
        const rdrToParts = e.currentTarget.href.split("?");
        const rdrTo = `${rdrToParts[0]}${
            rdrToParts[1] ? `?${encodeURIComponent(rdrToParts[1])}` : ""
        }`;
        $(e.currentTarget).attr(
            "href",
            `https://pdf-services.${
                Util.TOP_DOMAIN
            }/api/v1/auth/handoff?${qp}&redirect_to=${encodeURIComponent(
                rdrTo
            )}`
        );
    });
});
